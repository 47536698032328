<template>
  <div
    :style="{ background: loginScreenStyle.background }"
    class="session-wrapper login-screen">
    <v-container
      grid-list-xl
      class="column-layout justify-center full-height">
      <div class="text-xs-center">
        <div class="session-table-cell row-layout-center">
          <div class="session-content login-content">
            <div class="login-logo">
              <img
                :src="require('../../assets/images/logo.svg')"
                alt="Drive Me">
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Request from '../../helpers/request'
// import { storeToken } from '../../helpers/tokenHelper'

export default {
  mixins: [Request],
  computed: {
    loginScreenStyle () {
      return {
        background: `linear-gradient(to bottom, ${this.$store.getters.colorGuide.login.gradient.from} 40vh, ${this.$store.getters.colorGuide.login.gradient.to} 100%), url(${this.$store.getters.colorGuide.login.image}) ${this.$vuetify.breakpoint.xsOnly ? this.$store.getters.colorGuide.login.imageParams : this.$store.getters.colorGuide.login.imageParamsDesktop}`
      }
    }
  },
  beforeMount () {
    if (this.$route.query.token) {
      let body = {
        data: {
          token: this.$route.query.token
        }
      }

      this.requestNoAuth('POST', '/recover', body, ({ data }) => {
        // storeToken(data)

        this.constructRequests()

        this.request('GET', '/me', {}, ({ data }) => {
          this.$store.dispatch('loginUser', { data })
          this.$store.dispatch('setUser', data)
          this.$router.push('/signup')
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login-content {
      width: 90%;
      max-width: 350px;
}
.login-logo {
    margin-bottom: 10%;
    margin-top: 40px;
}
</style>
